import React from "react";
import { Dialog, Box, DialogContent } from "@mui/material";

const QrDialog = ({
  children,
  open,
  handleCloseMenu,
  fullScreen,
  keepMounted = false,
  sx = {},
  className = "",
  customStyle = {},
  maxWidth = "md",
}) => {
  return (
    <Box className="dialog-container">
      <Dialog
        transitionDuration={{ enter: 0, exit: 0 }}
        open={open}
        maxWidth={maxWidth}
        fullWidth={true}
        onClose={handleCloseMenu}
        fullScreen={fullScreen}
        keepMounted={keepMounted}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{
        //   background: "#ffffff2e",
        // }}
        PaperProps={{
          sx: {
            ...customStyle,
            ".MuiDialogContent-root": {
              p: fullScreen ? 0 : "24px",
            },
          },
        }}
      >
        <DialogContent>
          <Box sx={{ ...sx }} className={className}>
            {children}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default QrDialog;
