import { Box } from "@mui/material";
import Currency from "../components/Currency";

export const parseFloatPrice = (price) => {
  return parseFloat(price).toFixed(2);
};

export const parseFloatAmd = (price) => {
  return parseFloatPrice(price).split(".")[0];
};

export const showPriceRange = (priceRange, countryCode, currencyCode) => {
  return priceRange?.min === priceRange?.max ? (
    <Currency
      value={priceRange?.min}
      local={countryCode}
      currency={currencyCode}
    />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        fontFamily: "Roboto,sans-serif",
      }}
    >
      <Currency
        value={priceRange?.min}
        local={countryCode}
        currency={currencyCode}
      />
      <Box component="span" sx={{ ml: 0.5, mr: 0.5 }}>
        -
      </Box>
      <Currency
        value={priceRange?.max}
        local={countryCode}
        currency={currencyCode}
      />
    </Box>
  );
};
