import {
  styled, Typography, Card, Box
} from "@mui/material";

export const MenuTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Noto Sans Buhid, sans-serif",
  fontSize: '26px',
  lineHeight: "24px",
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Noto Sans Buhid, sans-serif",
  fontSize: '20px',
  lineHeight: "24px",
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const ProductCard = styled(Card)(({ theme }) => ({
  margin: "15px auto",
  minHeight: "144px",
  position: "relative",
  borderRadius: "5px",
  cursor: "pointer",
  width: "100%",
  minWidth: 275,
  marginTop: theme.spacing(2),
  boxShadow: '0 0 0 transparent',
  display:"flex",
  justifyContent:"space-between"
}));

export const ProductTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Noto Sans Buhid, sans-serif",
  fontSize: '20px',
  lineHeight: "24px",
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: "22.4px",
  },
  marginBottom: theme.spacing(1),
}));

export const ProductDescription = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '19.6px',
    fontSize: '12px'
  },
  margin: theme.spacing(1,2,1.5,0),
  wordBreak: "break-word",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box !important",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  whiteSpace: "pre-line",
}));

export const ProductRange = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  lineHeight: '29px',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '19px',
    fontSize: '16px'
  },
  fontFamily: "Roboto, sans-serif",
  position: "absolute",
  bottom: "15px",
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 2, 2, 0),
}));

export const ImageContent = styled(Box)(({  }) => ({
  width: "140px",
  height: "140px",
  '> img': {
    borderRadius: "5px",
    width: "100%",
    height: "100%",
  }
}));