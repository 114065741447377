import React, {useContext, useState} from "react";
import {Box, Grid} from "@mui/material";
import MenuCategories from "./MenuCategories";
import Loader from "components/Loader/Loader";
import {QrMenuContext} from "context/businessAndListContext";
import EmptyData from "components/EmptyData";

const MenuWrapper = ({ menuData }) => {
  const [loading, setLoading] = useState(false);
  const dataContext = useContext(QrMenuContext);


  return (
    <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
      {loading && (
        <Loader />
      )}
      {!loading && menuData && menuData?.length ? (
        menuData.map((menu) => {
          return (
            <Grid
              key={menu.id}
              item
              md={8}
              lg={8}
              xl={8}
              sm={12}
              xs={12}
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ width: '100%' }}>
                <MenuCategories menu={menu} dataContext={dataContext} count={menuData?.length}/>
              </Box>
            </Grid>
          )
        })
      ) : (
        <EmptyData styles={{ mt: 0, mb: 5 }} text="No result found" />
      )}
    </Grid>
  )
}

export default MenuWrapper