import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme/theme";
import "./styles/index.scss";
import App from "./App";
import Page404 from "./pages/Page404";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path=":restaurant" element={<App />} />
          <Route path="/" element={<Page404 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);
