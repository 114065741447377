import {
  Box,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInput from "react-phone-input-2";
import { THEME_MODE } from "../../constants/enums";
import QrDialog from "../Dialog/QrDialog";
import variables from "../../styles/variables.scss";
import useQuery from "../../hooks/useQuery";

const ContactInfoDialog = (props) => {
  const { data, open, dataContext, handleCloseMenu } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("1024"));
  const query = useQuery();
  const showContact = query.get("showContact");
  const [phoneNumberFormat, setPhoneNumberFormat] = useState(data?.phone || "");

  const styles = {
    header: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      mb: 4,
    },
    headerMobile: {
      background:
        THEME_MODE.DARK === dataContext.themeColor ? "#3A3A3C" : "#F7F8F9",
      color: THEME_MODE.DARK === dataContext.themeColor ? "#fff" : "#1C1C1E",
    },
    address: {
      display: "flex",
      fontFamily: "Noto Sans Buhid, sans-serif",
      fontSize: { xs: 16, sm: 16, md: 20 },
      lineHeight: { xs: "25.6px", sm: "25.6px", md: "24px" },
      ml: 2,
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#000",
    },
    phoneInputContainer: {
      display: "flex",
      ml: 2,
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#000000",
      fontFamily: "Noto Sans Buhid, sans-serif",
      fontSize: { xs: 16, sm: 16, md: 20 },
      lineHeight: "24px",
      whiteSpace: "nowrap",
    },
    contactInfo: {
      background:
        THEME_MODE.DARK === dataContext.themeColor
          ? "#1c1c1e"
          : variables.whiteColor,
    },
  };

  useEffect(() => {
    if (
      (window.innerWidth < 1024 && open) ||
      (window.innerWidth < 1024 && showContact)
    ) {
      document.getElementById("root").style.display = "none";
      // document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementById("root").style.display = "flex";
    }
    // document.getElementsByTagName("body")[0].style.overflow = "inherit";
  }, [open, showContact]);

  const renderContent = () => {
    return (
      <Box>
        <Box sx={styles.headerMobile} className="header-mobile">
          <Box
            className="header-mobile-icon"
            onClick={handleCloseMenu}
            sx={{
              background:
                THEME_MODE.DARK === dataContext.themeColor
                  ? "rgb(28, 28, 30, 20%)"
                  : "#F2F2F2",
            }}
          >
            <KeyboardBackspaceIcon />
          </Box>
        </Box>
        <Box className="header-mobile-title">
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: "Noto Sans Buhid, sans-serif",
              fontSize: { xs: 20, sm: 20, md: 24 },
              lineHeight: { xs: "24px", sm: "24px", md: "29px" },
              color:
                THEME_MODE.DARK === dataContext.themeColor
                  ? variables.whiteColor
                  : "#000",
            }}
            variant="h5"
          >
            {data?.title}
          </Typography>
        </Box>
        <Box className="header-desktop" sx={styles.header}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: "Noto Sans Buhid, sans-serif",
              fontSize: { xs: 20, sm: 20, md: 24 },
              lineHeight: { xs: "24px", sm: "24px", md: "29px" },
              color:
                THEME_MODE.DARK === dataContext.themeColor
                  ? variables.whiteColor
                  : "#000",
            }}
            variant="h5"
          >
            {data?.title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseMenu}
            sx={{
              p: 0,
              color:
                THEME_MODE.DARK === dataContext.themeColor
                  ? variables.whiteColor
                  : "#000",
            }}
          >
            <CloseIcon
              sx={{
                width: "34.29px",
                height: "34.29px",
              }}
            />
          </IconButton>
        </Box>
        <Box
          className="header-mobile-content"
          display="flex"
          sx={{ alignItems: "center", mb: 3.125 }}
        >
          <LocalPhoneIcon sx={{ color: "#FF8A00" }} />
          <Typography
            sx={styles.phoneInputContainer}
            variant="h6"
            component="h6"
          >
            <Link
              sx={{
                ...styles.phoneInputContainer,
                ml: 0,
                textDecoration: "none",
              }}
              href={`tel:${phoneNumberFormat || data?.phone}`}
            >
              {phoneNumberFormat || data?.phone}
            </Link>
            <PhoneInput
              style={{ visibility: "hidden" }}
              ref={(el) => {
                setPhoneNumberFormat(el?.state?.formattedNumber);
              }}
              initialValueFormat="national"
              value={data?.phone}
              defaultCountry="US"
              className={`${
                THEME_MODE.DARK === dataContext.themeColor
                  ? "phone-field-dark"
                  : "phone-field-light"
              } phone-field`}
              disabled={true}
            />
          </Typography>
        </Box>
        <Box
          className="header-mobile-content"
          display="flex"
          alignItems="center"
        >
          <LocationOnIcon sx={{ color: "#FF8A00" }} />
          <Typography
            fontSize={{ xs: "16px", md: "20px" }}
            sx={styles.address}
            variant="h6"
            component="h6"
          >
            <Link
              sx={{
                ml: 0,
                textDecoration: "none",
                color:
                  THEME_MODE.DARK === dataContext.themeColor
                    ? variables.whiteColor
                    : "#000000",
                fontFamily: "Noto Sans Buhid, sans-serif",
                fontSize: { xs: 16, sm: 16, md: 20 },
              }}
              target="_blank"
              href={`https://www.google.com/maps?q=${data?.address}`}
            >
              {data?.address}
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <QrDialog
      {...props}
      customStyle={styles.contactInfo}
      fullScreen={fullScreen}
      maxWidth={"sm"}
      className="contact-info"
    >
      {renderContent()}
    </QrDialog>
  );
};

export default ContactInfoDialog;
