import React, { useEffect, useState } from "react";
import { THEME_MODE } from "constants/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CardContent } from "@mui/material";
import { showPriceRange } from "utils/helpers";
import variables from "styles/variables.scss";
import {
  CategoryTitle,
  ImageContent,
  ImageWrapper,
  MenuTitle,
  ProductCard,
  ProductDescription,
  ProductRange,
  ProductTitle,
} from "./styles";
import useQuery from "hooks/useQuery";
import ProductDialog from "../QrDialogComponents/ProductDialog";
const MenuCategories = ({ menu, dataContext, count }) => {
  const {
    menuCurrency: { countryCode, currencyCode },
  } = dataContext;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const query = useQuery();
  const pid = query.get("pid");
  const cid = query.get("cid");
  const mid = query.get("mid");
  const handleCloseMenu = () => {
    setOpen(false);
    if (mid && cid) {
      navigate(`?mid=${mid}&cid=${cid}`);
    }
  };

  const handleEvent = () => {
    setOpen(false);
    if (mid && cid) {
      navigate(`?mid=${mid}&cid=${cid}`);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  }, []);

  useEffect(() => {
    if (pid && cid && menu?.categories?.length) {
      menu?.categories.map((item) => {
        if (item.id === cid) {
          item.products.map((items) => {
            if (items.id === pid) {
              const modalDataProduct = {
                title: items.name,
                description: items.description,
                images: items.pictures,
                priceRange: items.priceRange,
                classificationsNames: items.classificationsNames,
                status: items.status,
                calories: items.calories,
                catStatus: item.status,
              };
              setModalData(modalDataProduct);
            }
          });
        }
      });
      if (pid) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [pid, cid, menu]);

  const handleOpenMenu = (e, item) => {
    setOpen(true);
    const modalDataProduct = {
      title: item.name,
      description: item.description,
      images: item.pictures,
      priceRange: item.priceRange,
      price: item.price,
      classificationsNames: item.classificationsNames,
      status: item.status,
      calories: item.calories,
    };
    let query = "";
    if (mid) {
      query += `?mid=${mid}`;
    }
    if (item.categoryId) {
      query += mid ? `&cid=${item.categoryId}` : `?cid=${item.categoryId}`;
    }
    if (item.id) {
      query += `&pid=${item.id}`;
    }
    navigate(`${query}`);
    setModalData(modalDataProduct);
  };

  useEffect(() => {
    if (!open) {
      setModalData(null);
      navigate(location.pathname);
    }
  }, [open]);

  const getDefaultImage = (pictures) => {
    const defaultImage = pictures?.find((item) => item.default);
    return (
      <ImageWrapper flexDirection="row">
        <ImageContent className="product-image">
          <img
            src={`${
              defaultImage?.url || pictures[0]?.url
            }?tr=h-140%2Cw-140,cm-pad_resize`}
          />
        </ImageContent>
      </ImageWrapper>
    );
  };

  const convertColor = (dataContext, color) => {
    return THEME_MODE.DARK === dataContext.themeColor
      ? variables.whiteColor
      : color;
  };

  const convertBackgroundColor = (dataContext, color) => {
    return THEME_MODE.DARK === dataContext.themeColor
      ? variables.themeDarkBlueBackground
      : color;
  };
  return (
    <>
      {modalData?.status && (
        <ProductDialog
          open={open}
          handleCloseMenu={handleCloseMenu}
          data={modalData}
          dataContext={dataContext}
          currentMenu={menu}
        />
      )}
      {count > 1 && (
        <MenuTitle sx={{ color: convertColor(dataContext, "#141414") }}>
          {menu.name}
        </MenuTitle>
      )}
      {menu?.categories && menu?.categories?.length
        ? menu?.categories.map((cat) => {
            if (cat?.status && cat?.products?.length > 0) {
              return (
                <Box
                  component="section"
                  id={`section-${cat.id.toString()}`}
                  name={`name-${cat.id.toString()}`}
                  key={"display" + cat.id}
                  data-section
                >
                  <CategoryTitle
                    sx={{ color: convertColor(dataContext, "#000000") }}
                  >
                    {cat.name}
                  </CategoryTitle>
                  {cat?.products && cat?.products?.length
                    ? cat?.products.map((product) => {
                        if (product?.status) {
                          return (
                            <Box
                              flexDirection="column"
                              key={product.id}
                              onClick={(e) => handleOpenMenu(e, product)}
                            >
                              <ProductCard
                                sx={{
                                  background: convertBackgroundColor(
                                    dataContext,
                                    "#F4F4F4"
                                  ),
                                }}
                              >
                                <CardContent>
                                  <ProductTitle
                                    sx={{
                                      color: convertColor(
                                        dataContext,
                                        "#000000"
                                      ),
                                    }}
                                  >
                                    {product?.name}
                                  </ProductTitle>
                                  <ProductDescription
                                    sx={{
                                      color: convertColor(
                                        dataContext,
                                        "#000000"
                                      ),
                                    }}
                                  >
                                    {product.description}
                                  </ProductDescription>
                                  <ProductRange
                                    sx={{
                                      color: convertColor(
                                        dataContext,
                                        "#000000"
                                      ),
                                    }}
                                    component={"span"}
                                  >
                                    {showPriceRange(
                                      product.priceRange,
                                      countryCode,
                                      currencyCode
                                    )}
                                  </ProductRange>
                                </CardContent>
                                {product?.pictures?.length ? (
                                  getDefaultImage(product.pictures)
                                ) : (
                                  <></>
                                )}
                              </ProductCard>
                            </Box>
                          );
                        }
                      })
                    : ""}
                </Box>
              );
            }
          })
        : ""}
    </>
  );
};

export default MenuCategories;
