import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { QrMenuContext } from "../context/businessAndListContext";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";
import contactInfo from "../assets/icons/contactInfo.svg";
import contactInfoDark from "../assets/icons/contactInfoDark.svg";
import menuOpen from "../assets/icons/menuOpen.svg";
import menuOpenDark from "../assets/icons/menuOpenDark.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ContactInfoDialog from "./QrDialogComponents/ContactInfoDialog";
import HeaderMenuList from "./HeaderMenuList.js";

const HeaderQR = ({
  allDataList,
  setValue = () => {},
  setMenuId,
  setOpenMenu,
  openMenu,
  menuId,
  filteredStatus,
  selectedIndex,
  setSelectedIndex = () => {},
  setLogoClicked = () => {},
  setMenuIsClicked,
  setCurrentMenuId,
}) => {
  const dataContext = useContext(QrMenuContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const contact = query.get("showContact");
  const mid = query.get("mid");
  const { logo, description = null } = dataContext.business || {
    phoneNumber: "",
  };
  // const [selectedIndex, setSelectedIndex] = useState(menuId);
  const { menuList } = dataContext;
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery("(max-width:1024px)");
  const firstCat = filteredStatus?.[0]?.id || "";

  //   const redMoreShow = () => {
  //     const readMore = document.getElementById("read-more");
  //     const el = document.getElementById("wrapper");
  //     const elHidden = document.getElementById("wrapper-hidden");
  //     if (el && elHidden) {
  //       const textContent = description;
  //       if (!toggle) {
  //         if (getLines(elHidden) > 3) {
  //           el.classList.add("linesStyle");
  //           return (readMore.style.display = "block");
  //         } else {
  //           readMore.style.display = "none";
  //           return el.classList.remove("linesStyle");
  //         }
  //       } else {
  //         el.innerHTML = textContent;
  //         el.classList.remove("linesStyle");
  //       }
  //     }
  //   };

  useEffect(() => {
    // redMoreShow();
    if (open) {
      setOpenMenu(false);
    }
  }, [toggle, mid, openMenu, window.innerWidth, description, contact]);

  // useEffect(() => {
  //   if (menuList?.length) {
  //     menuList.map((item, index) => {
  //       if (item.id === mid) {
  //         setSelectedIndex(index);
  //         setMenuId(index);
  //       }
  //     });
  //   }
  // }, [mid, menuList]);

  useEffect(() => {
    if (contact) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [contact]);

  const handleOpenMenu = () => {
    if (!dataContext?.menuList?.length) {
      navigate(`${location.pathname}?showContact=true`);
    } else {
      navigate(
        mid
          ? `${location.search}&showContact=true`
          : `${location.search}?showContact=true`
      );
    }
    setOpen(true);
  };

  const handleOpenMenuList = () => {
    setOpenMenu(!openMenu);
    setMenuIsClicked(true);
  };

  const handleCloseMenuList = () => {
    setOpenMenu(!openMenu);
    setMenuIsClicked(false);
  };

  return (
    <>
      {(matches && !openMenu) || !matches ? (
        <Box
          id="topContainer"
          sx={{
            background:
              THEME_MODE.DARK === dataContext.themeColor
                ? "#383838"
                : "#EBEBEB",
            width: "100%",
            pt: 1.5,
            pb: 1.5,
            display: "flex",
            justifyContent: "center",
            // position: "sticky",
            top: -1,
            zIndex: 999,
          }}
        >
          <Grid container maxWidth="xl" display="flex" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={9}
              xl={9}
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              sx={{ pl: 1.5, pr: 1.5 }}
            >
              <Box display="flex">
                <Box
                  sx={{
                    opacity: allDataList?.length ? 1 : 0.3,
                    cursor: allDataList?.length ? "pointer" : "not-allowed",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color:
                      THEME_MODE.DARK === dataContext.themeColor
                        ? variables.whiteColor
                        : variables.themeDarkBlackBackground,
                  }}
                  backgroundColor={
                    THEME_MODE.DARK === dataContext.themeColor
                      ? variables.themeDarkGrayBackground
                      : "#D5D5D5"
                  }
                  width="48px"
                  height="48px"
                  display="flex"
                  onClick={allDataList?.length ? handleOpenMenuList : () => {}}
                >
                  {!openMenu ? (
                    <Box
                      component="img"
                      src={
                        THEME_MODE.DARK === dataContext.themeColor
                          ? menuOpenDark
                          : menuOpen
                      }
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        width: "34.29px",
                        height: "34.29px",
                      }}
                      onClick={handleCloseMenuList}
                    />
                  )}
                </Box>
              </Box>
              {logo?.url && (
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setValue(0);
                    setLogoClicked(true);
                    setSelectedIndex(0);
                    setMenuId(dataContext?.menuList?.[0]?.id);
                    window.location.href = `${window.location.origin}${
                      window.location.pathname
                    }?mid=${
                      dataContext?.menuList?.[0]?.id || mid
                    }&cid=${firstCat}`;
                  }}
                  component="img"
                  src={`${logo.url}?tr=${"w-88,h-88,"}bg-F0F0F0,cm-pad_resize`}
                />
              )}
              <Grid item>
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color:
                      THEME_MODE.DARK === dataContext.themeColor
                        ? variables.whiteColor
                        : variables.themeDarkBlackBackground,
                    cursor: "pointer",
                  }}
                  backgroundColor={
                    THEME_MODE.DARK === dataContext.themeColor
                      ? variables.themeDarkGrayBackground
                      : "#D5D5D5"
                  }
                  width="48px"
                  height="48px"
                  display="flex"
                  onClick={handleOpenMenu}
                >
                  <Box
                    component="img"
                    src={
                      THEME_MODE.DARK === dataContext.themeColor
                        ? contactInfoDark
                        : contactInfo
                    }
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
      {openMenu && (
        <HeaderMenuList
          handleCloseMenu={handleCloseMenuList}
          openMenu={openMenu}
          menuId={menuId}
          setMenuId={setMenuId}
          setOpenMenu={setOpenMenu}
          firstCat={firstCat}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setCurrentMenuId={setCurrentMenuId}
          allDataList={allDataList}
        />
      )}
    </>
  );
};

export default HeaderQR;
