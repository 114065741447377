import React from "react";
import { Box, Typography } from "@mui/material";
import emptyCategory from "../assets/icons/emptyCategory.svg";

const EmptyData = ({
  text = "No result found",
  color = "#777778",
  variant = "p",
  styles = {},
  accessIcon = true,
}) => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {accessIcon ? (
        <Box
          component="img"
          src={emptyCategory}
          sx={{
            mt: 5,
            mb: 1,
          }}
        />
      ) : (
        <></>
      )}
      <Typography
        sx={{
          ...styles,
          color: color,
        }}
        align="center"
        variant={variant}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default EmptyData;
