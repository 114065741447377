import React from "react";
import Layout from "./pages/NewVersion/Layout";

function App() {
  return (
    <Layout />
  )
}

export default App;
