import React, { useContext } from "react";
import {
  Box,
  Grid,
  Divider,
  MenuList,
  MenuItem,
  Link,
} from "@mui/material";
import { QrMenuContext } from "../context/businessAndListContext";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const HeaderMenuList = ({
  setValue,
  setMenuId,
  setOpenMenu,
  openMenu,
  menuId,
  setOpen,
  handleCloseMenu,
  firstCat,
  selectedIndex,
  setSelectedIndex = () => {},
  setCurrentMenuId,
  allDataList
}) => {
  const dataContext = useContext(QrMenuContext);
  const navigate = useNavigate();
  const query = useQuery();
  const mid = query.get("mid");
  const { menuList } = dataContext;
  const filteredMenu = allDataList?.sort((a, b) => {
    return a.sort - b.sort;
  });

  const findIndexMenu = allDataList?.findIndex((item) => item.id === mid);
  //   const [open, setOpen] = useState(false);
  // const [toggle, setToggle] = useState(false);
  // const matches = useMediaQuery("(max-width:1024px)");

  const handleMenuItemClick = (_, index, option) => {
    setSelectedIndex(index);
    setMenuId(index);
    setOpenMenu(false);
    allDataList.forEach((item) => {
      if(item?.id === option?.id) {
        setCurrentMenuId(item?.categories[0]?.id)
      }
    })
    // setCurrentMenuId(id);
    // window.location.href = `${window.location.origin}${window.location.pathname}?mid=${menuList[index].id}&cid=${firstCat}`;
    // // navigate(`?mid=${menuList[index].id}&cid=${firstCat}`, { replace: true });
    // setOpenMenu(false);
  };

  // const handleOpenMenu = () => {
  //   if (!dataContext?.menuList?.length) {
  //     navigate(`${location.search}?showContact=true`);
  //   } else {
  //     navigate(`${location.search}&showContact=true`);
  //   }
  //   setOpen(true);
  // };

  return (
    <Box
      sx={{
        width: "100%",
        background:
          THEME_MODE.DARK === dataContext.themeColor ? "#1C1C1E " : "#FFFFFF",
        display: "flex",
        justifyContent: "center",
      }}
      className={openMenu ? "mobile-menu" : ""}
    >
      <Grid container maxWidth="xl" justifyContent="center">
        <Grid sx={{ display: openMenu ? "block" : "none" }} item xs={12}>
          <Box component="header" className="header-mobile">
            <Box
              className="header-mobile-icon"
              sx={{
                background:
                  THEME_MODE.DARK === dataContext.themeColor
                    ? variables.themeDarkGrayBackground
                    : "#F2F2F2",
                cursor: "pointer",
                color:
                  THEME_MODE.DARK === dataContext.themeColor
                    ? variables.whiteColor
                    : variables.themeDarkBlackBackground,
              }}
              onClick={handleCloseMenu}
            >

              <KeyboardBackspaceIcon />
            </Box>
          </Box>
        </Grid>
        <Grid
          className="mobile-menu-list"
          item
          xs={12}
          sm={12}
          md={10}
          lg={9}
          xl={9}
          sx={{ pl: 1.5, pr: 1.5 }}
        >
          <MenuList
            dense
            sx={{
              display: openMenu ? "block" : "none",
              background:
                THEME_MODE.DARK === dataContext.themeColor
                  ? variables.themeDarkBlueBackground
                  : "#FFFFFF",
              boxShadow:
                " 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {filteredMenu?.map((option, index) => {
              return (
                <Link
                  key={`${option}-${index}`}
                  sx={{
                    textDecoration: 'none',
                    fontWeight: 700,
                    color:
                      selectedIndex === index &&
                      THEME_MODE.DARK === dataContext.themeColor
                        ? variables.themeDarkGreyColor
                        : selectedIndex === index &&
                        THEME_MODE.LIGHT === dataContext.themeColor
                          ? "#737475"
                          : "black",
                  }}
                  // href={`#${option.name}`}
                  >

                  <MenuItem
                    sx={{
                      p: 0,
                      color:
                        THEME_MODE.DARK === dataContext.themeColor
                          ? variables.whiteColor
                          : "inherit",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      fontSize: "16px",
                      "&.Mui-selected": {
                        "&:hover": {
                          backgroundColor:
                            THEME_MODE.DARK === dataContext.themeColor
                              ? "#00000026"
                              : "#F2F2F2",
                        },
                        backgroundColor:
                          THEME_MODE.DARK === dataContext.themeColor
                            ? "#00000026"
                            : "#F2F2F2",
                      },
                    }}
                    selected={index === findIndexMenu}
                    onClick={(event) => handleMenuItemClick(event, index, option)}
                  >
                    <Box display="flex" alignItems="center">
                      <Box
                        sx={{
                          color:
                            selectedIndex === index &&
                            THEME_MODE.DARK === dataContext.themeColor
                              ? variables.themeDarkGreyColor
                              : selectedIndex === index &&
                              THEME_MODE.LIGHT === dataContext.themeColor
                                ? "#737475"
                                : "",
                          padding: "24px 0",
                          textAlign: "center",
                          whiteSpace: "pre-line",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: { xs: 16, sm: 16, md: 20 },
                          lineHeight: "24px",
                        }}
                      >
                        {option.name}
                      </Box>
                    </Box>
                    {menuList.length - 1 !== index && (
                      <Divider
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  </MenuItem>
                </Link>
              );
            })}
          </MenuList>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderMenuList;
