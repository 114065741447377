import React from "react";
import EmptyData from "../components/EmptyData";

const Page404 = () => {
  return (
    <EmptyData
      text="Not found business"
      styles={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "100vh",
      }}
      variant="h4"
      color="#666666b5"
      accessIcon={false}
    />
  );
};

export default Page404;
