import React from "react";
import {Box} from "@mui/material";
import variables from "styles/variables.scss";
import LoadingIcon from "assets/loading.gif";


const Loader = () => {
  return (
    <Box
      className="circular-progress"
      sx={{
        background: variables.whiteColor,
      }}
    >
      <Box component="img" src={LoadingIcon} />
    </Box>
  )
}

export default Loader