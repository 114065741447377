import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { QrMenuContext } from "../context/businessAndListContext";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ContactInfoDialog from "./QrDialogComponents/ContactInfoDialog";

import useQuery from "../hooks/useQuery";

const HeaderTitle = ({
  setValue,
  setMenuId,
  setOpenMenu,
  openMenu,
  menuId,
}) => {
  const dataContext = useContext(QrMenuContext);
  const query = useQuery();
  const contact = query.get("showContact");
  const mid = query.get("mid");
  const { name } = dataContext.business;
  const { menuList } = dataContext;
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (open) {
      setOpenMenu(false);
    }
  }, [mid, openMenu, window.innerWidth, contact]);

  useEffect(() => {
    if (menuList?.length) {
      menuList.map((item, index) => {
        if (item.id === mid) {
          setMenuId(index);
        }
      });
    }
  }, [mid, menuList]);

  useEffect(() => {
    if (contact) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [contact]);

  return (
    <Box width="100%">
      {(matches && !openMenu) || !matches ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3,
            pb: 3,
          }}
        >
          <Grid
            container
            justifyContent="center"
            maxWidth="xl"
            display="flex"
            sx={{ pl: 1.5, pr: 1.5 }}
          >
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={10}
              lg={9}
              xl={9}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: { xs: 20, sm: 20, md: 24 },
                    lineHeight: "29px",
                    color:
                      THEME_MODE.DARK === dataContext.themeColor
                        ? variables.whiteColor
                        : "#000000",
                  }}
                  align="center"
                >
                  {name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default HeaderTitle;
