import React, { useContext, useEffect, useRef, useState } from "react";
import Page404 from "pages/Page404";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { getRequest } from "services/axiosClient";
import { QrMenuContext } from "context/businessAndListContext";
import HeaderMenu from "components/HeaderMenu";
import { THEME_MODE } from "constants/enums";
import { HeaderTitleBox } from "./styles";
import { Box, Grid } from "@mui/material";
import HeaderQR from "components/HeaderQR";
import FooterQR from "components/FooterQR";
import variables from "styles/variables.scss";
import HeaderTabQR from "components/HeaderTabQR";
import HeaderTitle from "components/HeaderTitle";
import MenuWrapper from "components/newVersion/MenuWrapper";

const Layout = () => {
  const { restaurant } = useParams();
  const refElem = useRef();
  const [allDataList, setAllDataList] = useState([]);
  const [value, setValue] = useState(0);
  const [menuData, setMenuData] = useState(null);
  const sections = useRef([]);
  const [menuId, setMenuId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(menuId);
  const [logoClicked, setLogoClicked] = useState(false);
  const [menuIsClicked, setMenuIsClicked] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState("");
  const dataContext = useContext(QrMenuContext);

  function filterMenusAndProducts(menus) {
    if (menus && menus?.length) {
      return menus
        .map((menu) => {
          const filteredCategories = menu?.categories
            .map((category) => {
              const hasNotDisabledProduct = category?.products.some(
                (product) => product?.status
              );

              const productLength = category?.products?.length;

              return hasNotDisabledProduct || productLength < 0
                ? {
                    ...category,
                    products: category?.products.filter(
                      (product) => product?.status
                    ),
                  }
                : null;
            })
            .filter((category) => category !== null);
          let filteredCategoriesByStatus = filteredCategories.filter(
            (cat) => cat?.status
          );
          if (filteredCategoriesByStatus?.length > 0) {
            return { ...menu, categories: filteredCategories };
          }

          return null;
        })
        .filter((menu) => menu !== null);
    }
  }

  const collectDataCategoryAndItems = (menuList) => {
    if (menuList && menuList?.length) {
      setLoading(true);
      const promises = [];
      menuList.forEach((menu) => {
        promises.push(getRequest(`/web/menu/${menu?.id}`));
      });
      Promise.all(promises)
        .then((res) => {
          const newAllDataList = [];
          res.forEach((response) => {
            newAllDataList.push(response?.data?.data);
          });
          let filteredData = filterMenusAndProducts(newAllDataList);
          setAllDataList(filteredData);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(true);
    getRequest(`/web/business/${restaurant}`)
      .then((res) => {
        if (Object.values(res.data.data).length) {
          res.data.data.menuList = res.data.data?.menuList
            ?.filter((item) => item?.status)
            ?.sort((a, b) => a.sort - b.sort);
          setMenuData(res.data.data);
          if (res?.data?.data?.menuList?.length) {
            collectDataCategoryAndItems(res.data.data.menuList);
          }
        } else {
          setMenuData(null);
        }
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [restaurant]);

  useEffect(() => {
    if (menuData?.themeColor) {
      if (menuData.themeColor === "dark") {
        document.querySelector("body").style.backgroundColor = "#3A3A3C";
      } else {
        document.querySelector("body").style.backgroundColor = "#ffffff";
      }
    }
  }, [menuData?.themeColor]);

  const handleScroll = () => {
    const elem = refElem.current;
    const scrollPosition = window.scrollY; // => scroll position
    if (elem) {
      elem.style.top = 0;
      if (scrollPosition > 110) {
        elem.style.boxShadow = "0 1px 6px 0 rgb(32 33 36 / 28%)";
      } else {
        elem.style.boxShadow = "none";
      }
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const styleForMenuIsNot = !dataContext?.menuList?.length
    ? {
        display: "flex",
        alignItems: "center",
      }
    : {};

  return (
    <>
      {loading ? (
        <Loader />
      ) : !loading && !menuData ? (
        <Page404 />
      ) : (
        <QrMenuContext.Provider value={menuData}>
          <HeaderMenu
            allDataList={allDataList}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            setMenuId={setMenuId}
            setLogoClicked={setLogoClicked}
            filteredStatus={[]}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setMenuIsClicked={setMenuIsClicked}
            setCurrentMenuId={setCurrentMenuId}
          />
          <HeaderTitleBox
            sx={{
              // position: "absolute",
              zIndex: 99,
              backgroundColor:
                THEME_MODE.DARK === menuData?.themeColor
                  ? "#3A3A3C"
                  : "#F4F4F4",
              top: menuData?.business?.logo?.url ? "110px" : "71px",
            }}
            className="header-title"
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <HeaderTitle
                  setValue={setValue}
                  setMenuId={setMenuId}
                  menuId={menuId}
                  setOpenMenu={setOpenMenu}
                  openMenu={openMenu}
                />
              </Grid>
            </Grid>
          </HeaderTitleBox>
          <Box
            component="header"
            sx={{
              width: "100%",
              backgroundColor:
                THEME_MODE.DARK === menuData?.themeColor
                  ? "#3A3A3C"
                  : "#F4F4F4",
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <HeaderQR
                  setValue={setValue}
                  setMenuId={setMenuId}
                  menuId={menuId}
                  setOpenMenu={setOpenMenu}
                  openMenu={openMenu}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              position: "sticky",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              background:
                THEME_MODE.DARK === menuData?.themeColor
                  ? variables.themeDarkBlueBackground
                  : "#fff",
              textAlign: "center",
              top: menuData?.business?.logo?.url
                ? "calc(112px + 70px)"
                : "0",
            }}
            className="category-item"
            ref={refElem}
          >
            {allDataList && allDataList?.length ? (
              <HeaderTabQR
                allDataList={allDataList}
                themeColor={menuData.themeColor}
                setLogoClicked={setLogoClicked}
                logoClicked={logoClicked}
                sections={sections}
                setValue={setValue}
                value={value}
                dataContext={dataContext}
                currentMenuId={currentMenuId}
                menuId={menuId}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box
            component="section"
            width="100%"
            flex="auto"
            justifyContent="center"
            sx={{
              margin: "0 auto",
              background:
                THEME_MODE.DARK === menuData.themeColor
                  ? variables.themeDarkBlackBackground
                  : variables.whiteColor,
              ...styleForMenuIsNot,
            }}
          >
            <Box sx={{ width: "90%" }}>
              <Grid container justifyContent="center">
                <MenuWrapper
                  menuData={allDataList}
                  menuIsClicked={menuIsClicked}
                />
              </Grid>
            </Box>
          </Box>
          <Box
            component="footer"
            width="100%"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              minHeight: "80px",
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <FooterQR
                  styles={{
                    background:
                      THEME_MODE.DARK === menuData?.themeColor
                        ? variables.themeDarkBlueBackground
                        : variables.silverLightBackground,
                    color:
                      THEME_MODE.DARK === menuData?.themeColor
                        ? variables.whiteColor
                        : variables.blackColor,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </QrMenuContext.Provider>
      )}
    </>
  );
};

export default Layout;
