import React, { useEffect, useMemo, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import emptyImageProduct from "../../assets/icons/emptyImageProduct.svg";
import emptyImageProductDark from "../../assets/icons/emptyImageProductDark.svg";
import { THEME_MODE } from "../../constants/enums";
import QrDialog from "../Dialog/QrDialog";
import { parseFloatPrice, showPriceRange } from "../../utils/helpers";
import variables from "../../styles/variables.scss";
import useQuery from "../../hooks/useQuery";
import Currency from "../Currency";

const ProductDialog = (props) => {
  const { data, open, dataContext, currentMenu, handleCloseMenu } = props;
  const [imageSrc, setImageSrc] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("1024"));
  const query = useQuery();
  const pid = query.get("pid");
  const {
    menuCurrency: { countryCode, currencyCode },
  } = dataContext;
  const styles = {
    headerProduct: {
      position: "absolute",
      right: "15px",
      top: "15px",
      zIndex: 9,
    },
    headerMobileIcon: {
      background:
        THEME_MODE.DARK === dataContext.themeColor ? "#1C1C1E" : "#F2F2F2",
      color: THEME_MODE.DARK === dataContext.themeColor ? "#fff" : "#1C1C1E",
      cursor: "pointer",
    },
    productTitle: {
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#000",
      fontFamily: "Noto Sans Buhid, sans-serif",
      fontSize: { xs: 20, sm: 20, md: 24 },
      lineHeight: { xs: "32px", sm: "32px", md: "29px" },
      mb: 3,
    },
    description: {
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#000000",
      // fontFamily: "SF-Pro-Display-Regular",
      fontSize: { xs: 16, sm: 16, md: 20 },
      lineHeight: { xs: "25.6px", sm: "25.6px", md: "32px" },
      whiteSpace: "pre-line",
      mb: 3,
    },
    productDesciprtion: {
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#141414",
      fontFamily: "Noto Sans Buhid, sans-serif",
      fontSize: { xs: 20, sm: 20, md: 20 },
      lineHeight: "32px",
      // mb: 1,
    },
    productBackground: {
      background:
        THEME_MODE.DARK === dataContext.themeColor
          ? "#1c1c1e"
          : variables.whiteColor,
    },
    mainProduct: {
      width: data?.images?.length ? "100%" : "520px",
      bgcolor:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.themeDarkBlackBackground
          : variables.whiteColor,
    },
    priceRange: {
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#141414",
      fontFamily: "Roboto, sans-serif",
      fontSize: { xs: 20, sm: 20, md: 20 },
      lineHeight: { xs: "32px", sm: "32px", md: "24px" },
    },
    parseFloatPrice: {
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#141414",
      fontFamily: "Roboto, sans-serif",
      fontSize: { xs: 20, sm: 20, md: 20 },
      lineHeight: { xs: "24px", sm: "24px", md: "32px" },
    },
    closeIcon: {
      width: "34.29px",
      height: "34.29px",
    },
    classification: {
      background:
        THEME_MODE.DARK === dataContext.themeColor ? "#3A3A3C" : "#F6F6F6",
      p: 1,
      borderRadius: "5px",
      fontFamily: "Noto Sans Buhid, sans-serif",
      fontSize: { xs: 14, sm: 14, md: 16 },
      color:
        THEME_MODE.DARK === dataContext.themeColor
          ? variables.whiteColor
          : "#464646",
      lineHeight: { xs: "18px", sm: "18px", md: "19px" },
      mr: 1,
      mb: 1,
      display: "flex",
      alignItems: "center",
    },
    classificationIcons: {
      width: "20px",
      height: "20px",
      mr: "6.4px !important",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setImageSrc(false);
    }, 600);
  }, [open, imageSrc]);

  useEffect(() => {
    if (window.innerWidth < 1024 && open) {
      // document.getElementById("root").style.display = "none";
      document.getElementById("root").style.visibility = "hidden";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementById("root").style.display = "flex";
      document.getElementById("root").style.visibility = "visible";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
    document.getElementsByTagName("body")[0].style.overflow = "inherit";
  }, [open]);

  const showOptionPrice = useMemo(() => {
    const price = [];
    if (currentMenu?.categories) {
      currentMenu.categories.forEach((catItem) => {
        if (catItem?.products) {
          catItem.products.forEach((product) => {
            if (product.id === pid) {
              price.push(product.price);
            }
          });
        }
      });
    }
    return price;
  }, [pid, data]);

  const renderContent = () => {
    return (
      open &&
      data?.status && (
        <>
          <Box
            sx={{
              background:
                THEME_MODE.DARK === dataContext.themeColor
                  ? "#3A3A3C"
                  : "#F7F8F9",
            }}
            className="header-mobile sticky"
          >
            <Box
              onClick={handleCloseMenu}
              className="header-mobile-icon"
              sx={styles.headerMobileIcon}
            >
              <KeyboardBackspaceIcon />
            </Box>
          </Box>
          <Box className="header-desktop" sx={styles.headerProduct}>
            <IconButton
              aria-label="close"
              onClick={handleCloseMenu}
              sx={{
                p: 0,
                color:
                  THEME_MODE.DARK === dataContext.themeColor
                    ? variables.whiteColor
                    : "#000",
              }}
            >
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
          <Box display="flex" id="content-mobile-product-main">
            <Grid container justifyContent="center">
              {data?.images?.length ? (
                <Grid className="mobile-slider" item xs={6}>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    {imageSrc && (
                      <Box
                        sx={{
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          width: "100%",
                          position: "absolute",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    <Slide
                      autoplay={false}
                      canSwipe={data.images.length > 1 ? true : false}
                      indicators={data.images.length > 1 ? true : false}
                      arrows={false}
                      transitionDuration={400}
                    >
                      {data?.images?.map((slideImage, index) => (
                        <Box className="each-slide" key={index}>
                          <Box
                            component="img"
                            sx={{
                              width: 426,
                              height: 426,
                            }}
                            alt={data?.title}
                            src={`${slideImage.url}?tr=w-426,h-426,${
                              THEME_MODE.DARK === dataContext.themeColor
                                ? "bg-48484A"
                                : "bg-F0F0F0"
                            },cm-pad_resize`}
                            key={slideImage.fileId}
                          />
                        </Box>
                      ))}
                    </Slide>
                  </Box>
                </Grid>
              ) : // <Grid
              //   className="mobile-slider mobile-slider-empty"
              //   justifyContent="center"
              //   sx={{
              //     background:
              //       THEME_MODE.DARK === dataContext.themeColor
              //         ? "#48484A"
              //         : "#eee",
              //   }}
              //   item
              //   xs={6}
              // >
              //   {imageSrc && (
              //     <Box
              //       sx={{
              //         justifyContent: "center",
              //         alignItems: "center",
              //         display: "flex",
              //         width: "100%",
              //         height: "100%",
              //         position: "absolute",
              //       }}
              //     >
              //       <CircularProgress />
              //     </Box>
              //   )}
              //   <Box>
              //     <Box
              //       component="img"
              //       sx={{
              //         borderRadius: "5px",
              //         width: "117px",
              //         display: imageSrc ? "none" : "flex",
              //       }}
              //       src={
              //         THEME_MODE.DARK === dataContext.themeColor
              //           ? emptyImageProductDark
              //           : emptyImageProduct
              //       }
              //     ></Box>
              //   </Box>
              // </Grid>
              null}
              <Grid
                className="content-mobile-product-main"
                item
                sm={12}
                xs={12}
                md={data?.images?.length ? 6 : 12}
                lg={data?.images?.length ? 6 : 12}
              >
                <Box
                  sx={{
                    padding: "0 18px",
                  }}
                  flexDirection="column"
                  className="content-mobile-product"
                >
                  <Typography sx={styles.productTitle}>
                    {data?.title}
                  </Typography>
                  {data?.calories ? (
                    <Typography
                      sx={{
                        fontSize: { xs: 16, sm: 16, md: 20 },
                        // fontFamily: "SF-Pro-Display-Regular",
                        lineHeight: "32px",
                        color:
                          THEME_MODE.DARK === dataContext.themeColor
                            ? variables.whiteColor
                            : "#484848",
                      }}
                    >
                      {data.calories} Cal
                    </Typography>
                  ) : (
                    <></>
                  )}

                  <Box
                    border={"0.1px solid #FF9140"}
                    sx={{ mb: 2 }}
                    component="hr"
                  />
                  <Box
                    width="100%"
                    sx={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      display: "flex",
                    }}
                  >
                    {data?.classificationsNames?.length ? (
                      data.classificationsNames.map((item) => {
                        return (
                          <Box sx={styles.classification}>
                            {item.icon ? (
                              <Box
                                component="img"
                                sx={styles.classificationIcons}
                                src={item.icon}
                              />
                            ) : (
                              <></>
                            )}
                            {item.name}
                          </Box>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Typography sx={styles.description}>
                    {data?.description}
                  </Typography>
                  {showOptionPrice[0]?.length > 1 ? (
                    showOptionPrice[0]?.map((item, index) => {
                      return (
                        <Box
                          display="flex"
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                          }}
                          key={`${index}-${data?.title}`}
                        >
                          <Typography
                            sx={styles.productDesciprtion}
                            component={"span"}
                          >
                            {item.desciprtion}
                          </Typography>
                          <Typography
                            sx={styles.parseFloatPrice}
                            component={"span"}
                          >
                            <Currency
                              value={item.count}
                              local={countryCode}
                              currency={currencyCode}
                            />
                          </Typography>
                        </Box>
                      );
                    })
                  ) : data?.priceRange ? (
                    <Typography sx={styles.priceRange} component={"span"}>
                      {showPriceRange(
                        data?.priceRange,
                        countryCode,
                        currencyCode
                      )}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )
    );
  };

  return (
    <QrDialog
      {...props}
      customStyle={{ ...styles.productBackground, ...styles.mainProduct }}
      fullScreen={fullScreen}
      className="product-main"
      maxWidth={data?.images?.length ? "md" : "lg"}
    >
      {renderContent()}
    </QrDialog>
  );
};

export default ProductDialog;
