import React from "react";
import { parseFloatAmd } from "../utils/helpers";

const Currency = ({ value = 0, local = "en-US", currency = "USD" }) => {
  const options = {
    style: "currency",
    currency: currency,
  };

  if (currency === "AMD") {
    value = parseFloatAmd(value);
    options.minimumFractionDigits = 0;
  }

  const newCurrency = new Intl.NumberFormat(local, options);
  const filterArm = newCurrency.formatToParts(value).filter((p) => {
    return p.type === "currency" && p.value === "AMD";
  });
  const armFormat = filterArm?.reduce((s, p) => s + p.value, "") || "";
  return (
    <>
      {newCurrency.format(value).replace("AMD", "").trim()} {armFormat}
    </>
  );
};

export default Currency;
