import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosClient.defaults.timeout = 5000;
axiosClient.defaults.withCredentials = true;

export default axiosClient;
