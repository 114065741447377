import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  // Divider,
  // MenuList,
  // MenuItem,
} from "@mui/material";
import { QrMenuContext } from "../context/businessAndListContext";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ContactInfoDialog from "./QrDialogComponents/ContactInfoDialog";

import useQuery from "../hooks/useQuery";
// import contactInfo from "../assets/icons/contactInfo.svg";
// import contactInfoDark from "../assets/icons/contactInfoDark.svg";
// import menuOpen from "../assets/icons/menuOpen.svg";
// import menuOpenDark from "../assets/icons/menuOpenDark.svg";

// import HeaderMenu from "./HeaderMenu";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import HeaderMenuList from "./HeaderMenuList.js";

const HeaderQR = ({ setValue, setMenuId, setOpenMenu, openMenu, menuId }) => {
  const dataContext = useContext(QrMenuContext);
  // const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const contact = query.get("showContact");
  const mid = query.get("mid");
  const cid = query.get("cid");
  const {
    phoneNumber,
    logo,
    name,
    address,
    description = null,
  } = dataContext.business || {
    phoneNumber: "",
  };
  const [selectedIndex, setSelectedIndex] = useState(menuId);
  const { menuList } = dataContext;

  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery("(max-width:1024px)");

  const getLines = (el) => {
    const lineHeight = el.style.lineHeight;
    const factor = 100;
    el.style.lineHeight = factor + "px";
    const height = el.getBoundingClientRect().height;
    el.style.lineHeight = lineHeight;
    return Math.floor(height / factor);
  };

  const redMoreShow = () => {
    const readMore = document.getElementById("read-more");
    const el = document.getElementById("wrapper");
    const elHidden = document.getElementById("wrapper-hidden");
    if (el && elHidden) {
      const textContent = description;
      if (!toggle) {
        if (getLines(elHidden) > 3) {
          el.classList.add("linesStyle");
          return (readMore.style.display = "block");
        } else {
          readMore.style.display = "none";
          return el.classList.remove("linesStyle");
        }
      } else {
        el.innerHTML = textContent;
        el.classList.remove("linesStyle");
      }
    }
  };

  useLayoutEffect(() => {
    redMoreShow();
    if (open) {
      setOpenMenu(false);
    }
  }, [toggle, mid, openMenu, window.innerWidth, description, contact]);

  useEffect(() => {
    if (menuList?.length) {
      menuList.map((item, index) => {
        if (item.id === mid) {
          setSelectedIndex(index);
          setMenuId(index);
        }
      });
    }
  }, [mid, menuList]);

  useEffect(() => {
    if (contact) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [contact]);

  // const handleMenuItemClick = (_, index) => {
  //   setSelectedIndex(index);
  //   setMenuId(index);
  //   navigate(`?mid=${menuList[index].id}`);
  //   setOpenMenu(false);
  // };

  // const handleOpenMenu = () => {
  //   if (!dataContext?.menuList?.length) {
  //     navigate(`${location.search}?showContact=true`);
  //   } else {
  //     navigate(`${location.search}&showContact=true`);
  //   }
  //   setOpen(true);
  // };

  // const handleOpenMenuList = () => {
  //   setOpenMenu(!openMenu);
  // };

  // const handleCloseMenuList = () => {
  //   setOpenMenu(!openMenu);
  // };

  const handleCloseMenu = () => {
    // let query = location.search.split("&showContact=true");
    // if (!dataContext?.menuList?.length && !cid) {
    //   query = location.search.split("?showContact=true");
    // }
    navigate(location.pathname);
    setOpen(false);
    setOpenMenu(false);
  };

  const handleClick = () => {
    setToggle(!toggle);
  };

  // const filteredMenu = menuList?.sort((a, b) => {
  //   return a.sort - b.sort;
  // });

  const modalData = {
    title: "Contact Info",
    phone: phoneNumber,
    address: address,
  };

  return (
    <Box width="100%">
      <ContactInfoDialog
        open={open}
        data={modalData}
        handleCloseMenu={handleCloseMenu}
        dataContext={dataContext}
        target="contact"
      />

      {(matches && !openMenu) || !matches ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            // pt: 3,
            pb: 3,
          }}
        >
          <Grid
            container
            justifyContent="center"
            maxWidth="xl"
            display="flex"
            sx={{ pl: 1.5, pr: 1.5 }}
          >
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={10}
              lg={9}
              xl={9}
            >
              <Box>
                {/* <Typography
                  sx={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: { xs: 20, sm: 20, md: 24 },
                    lineHeight: "29px",
                    mb: 3,
                    color:
                      THEME_MODE.DARK === dataContext.themeColor
                        ? variables.whiteColor
                        : "#000000",
                  }}
                  align="center"
                >
                  {name}
                </Typography> */}
                {description ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                      maxWidth: "500px",
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: "pre-line",
                        visibility: "hidden",
                        position: "fixed",
                        maxWidth: 500,
                      }}
                      id="wrapper-hidden"
                    >
                      {description.replace(/\uFFFC/g, "")}
                    </Box>
                    <Box
                      sx={{
                        // fontFamily: "SF-Pro-Display-Regular",
                        fontSize: { xs: 16, sm: 16, md: 16 },
                        lineHeight: "25.6px",
                        color:
                          THEME_MODE.DARK === dataContext.themeColor
                            ? variables.whiteColor
                            : "#000000",
                        wordBreak: "break-word",
                        overflow: "hidden",
                        textAlign: "center",
                        whiteSpace: "pre-line",
                      }}
                      align="center"
                      id="wrapper"
                    >
                      {description.replace(/\uFFFC/g, "")}
                    </Box>
                    <Box
                      onClick={handleClick}
                      component="span"
                      sx={{
                        color: "#3581F3",
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: "4px 10px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                      id="read-more"
                    >
                      {!toggle ? "Read more" : "Less"}
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default HeaderQR;
