import React, {useContext, useEffect, useState} from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { Box, Tab, Grid, Typography } from "@mui/material";
import { QrMenuListContext } from "../context/menuContext";
import { useSearchParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";
import { webConfig } from "../constants/webConfig";

const HeaderTabQR = ({
 allDataList,
 setLogoClicked,
 themeColor,
 logoClicked,
 sections,
 currentMenuId,
 menuId
}) => {
  const query = useQuery();
  const cid = query.get("cid");
  const mid = query.get("mid");
  const [allFilteredCategory, setAllFilteredCategory] = useState([]);
  const [value, setValue] = useState({
    indexTab: 0,
    id: "",
  });
  const [activeSection, setActiveSection] = useState(
    `${webConfig.navItemIdPrefix}${cid}`
  );
  useEffect(() => {
    if(allDataList && allDataList?.length) {
      let allCategories = []
      allDataList.forEach((cat) => {
        cat?.categories?.filter(
          (item) => {
            if(item.status && item?.products?.length > 0) {
              allCategories.push(item)
            }
          }
        );
      })
      setAllFilteredCategory(allCategories);
    }
  },[allDataList])

  useEffect(() => {
    if(currentMenuId) {
      setValue({ indexTab: '', id: currentMenuId, clicked: true });
      const itemElement = document.getElementById(
        `${webConfig.categoryIdPrefix}${currentMenuId}`
      );
      window.scrollTo({
        top: itemElement?.offsetTop - 275,
        behavior: "smooth",
      });
    }
  },[currentMenuId])

  const handleChange = (event, newValue) => {
    const currentTab = event.target.offsetParent.id.replace(
      webConfig.navItemIdPrefix,
      ""
    );

    setValue({ indexTab: newValue, id: currentTab, clicked: true });
    // setSearchParams(`?mid=${mid}&cid=${currentTab}`);
    const itemElement = document.getElementById(
      `${webConfig.categoryIdPrefix}${currentTab}`
    );
    window.scrollTo({
      top: itemElement?.offsetTop - 280,
      behavior: "smooth",
    });
    setLogoClicked(false);
  };

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;
    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop - 280;
      const sectionHeight = section.offsetHeight;
      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
        setValue({
          ...value,
          id: section.id?.replace(webConfig.categoryIdPrefix, ""),
        });
      }
    });
    setLogoClicked(false);
    setActiveSection(newActiveSection?.replace(webConfig.categoryIdPrefix, ""));
  };

  useEffect(() => {
    sections.current = document.querySelectorAll("[data-section]");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const findActiveSection =
    allFilteredCategory.findIndex((item) => item.id === activeSection) || 0;
  return (
    <>
      {allFilteredCategory && allFilteredCategory?.length > 0 ? (
        <Grid
          container
          maxWidth="xl"
          justifyContent="center"
          sx={{ px: 1.5, py: 1.5 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={9}
            xl={9}
            sx={{ px: 5, position: "relative" }}
          >
            <Grid item xs={12}>
              <Box className="swiper-category">
                <Tabs
                  value={findActiveSection < 0 ? null : findActiveSection}
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  scrollButtons
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  id="menu"
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {allFilteredCategory.length > 0 ? (
                    allFilteredCategory.map((category) => {
                      return (
                        <Tab
                          sx={{ cursor: "pointer" }}
                          key={category.id}
                          id={`navItem-${category.id}`}
                          onClick={handleChange}
                          label={
                            <Typography
                              onClick={handleChange}
                              sx={{
                                color:
                                  THEME_MODE.DARK === themeColor
                                    ? variables.whiteColor
                                    : variables.blackColor,
                              }}
                            >
                              {category.name}
                            </Typography>
                          }
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>

  );
};

export default HeaderTabQR;
