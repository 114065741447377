import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import logoQr from "../assets/images/logo.png";
import logoQrLight from "../assets/images/logo_light.png";

import { QrMenuContext } from "../context/businessAndListContext";
import { THEME_MODE } from "../constants/enums";
import variables from "../styles/variables.scss";

const FooterQR = ({ styles }) => {
  const dataContext = useContext(QrMenuContext);
  const style = {
    termsAndPolice: {
      color:
        THEME_MODE.DARK === dataContext?.themeColor
          ? variables.whiteColor
          : variables.blackColor,
      textDecoration: "none",
      fontSize: "1rem",
      fontWeight: 600,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };

  return (
    <Box
      sx={{
        ...styles,
        display: "flex",
        justifyContent: "center",
        padding: "19px 0",
      }}
      className={`${
        !dataContext?.menuList?.length ? "empty-menu-footer " : ""
      } mobile-footer`}
    >
      <Grid container maxWidth="xl" display="flex" justifyContent="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={9}
          xl={9}
          justifyContent="space-between"
          display="flex"
          alignItems="center"
          sx={{ pl: 1.5, pr: 1.5 }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: styles.color,
                // THEME_MODE.DARK === dataContext.themeColor
                //   ? variables.whiteColor
                //   : "#000",
                mr: 1,
              }}
            >
              Powered by{" "}
            </Typography>
            <Box component="a" href="https://orders.co/">
              <Box
                width={80}
                component="img"
                className="desktop-logo-footer"
                src={
                  THEME_MODE.DARK === dataContext.themeColor
                    ? logoQrLight
                    : logoQr
                }
              />
              <Box
                width={80}
                component="img"
                className="mobile-logo-footer"
                src={
                  THEME_MODE.DARK === dataContext.themeColor
                    ? logoQrLight
                    : logoQr
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ mb: 1, ...style.termsAndPolice }}
              component="a"
              target="_blank"
              href="https://orders.co/privacy-policy/"
            >
              Privacy Policy
            </Box>
            <Box
              sx={style.termsAndPolice}
              component="a"
              target="_blank"
              href="https://orders.co/terms-of-use/"
            >
              Terms and conditions
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterQR;
